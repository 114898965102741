import React from 'react';
import '../../App.css';
import HomeVideoBanner from '../HomeVideo';

function Home() {
  return (
    <>
      <HomeVideoBanner />
    </>
  );
}

export default Home;